import React from 'react';
import classes from './Box.module.scss';
import theme from '../../theme/Theme';

enum Display {
  block = 'block',
  inline = 'inline',
  flex = 'flex',
  grid = 'grid',
  none = 'none'
}

enum JustifyContent {
  start = 'start',
  center = 'center',
  end = 'end',
  'flex-start' = 'flex-start',
  'flex-end' = 'flex-end',
  left = 'left',
  right = 'right',
  'space-between' = 'space-between'
}

enum AlignItems {
  start = 'start',
  center = 'center',
  end = 'end',
  'flex-start' = 'flex-start',
  'flex-end' = 'flex-end',
}

type BoxProps = {
  id?: string;
  children?: string | React.ReactNode;
  pt?: number,
  pb?: number,
  pl?: number,
  pr?: number,
  mt?: number,
  mb?: number,
  ml?: number,
  mr?: number,
  display?: Display | string,
  justifyContent?: JustifyContent  | string,
  alignItems?: AlignItems | string,
  alignSelf?: AlignItems | string,
  flexGrow?: number,
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse'; 
  className?:any,
  style?: object,
  onClick?: () => void,
  gap?: string | number,
  componentRef?: any,
  columnGap?: string | number,
  rowGap?: string | number
};

const Box: React.FC<BoxProps> = ({
  id,
  children,
  pt,
  pb,
  pl,
  pr,
  mt,
  mb,
  ml,
  mr,
  display,
  justifyContent,
  alignItems,
  alignSelf,
  flexGrow,
  flexDirection,
  flexWrap,
  style,
  className,
  onClick,
  gap,
  componentRef,
  columnGap,
  rowGap
}) => {
  return (
    <div
      id={id}
      ref={componentRef}
      className={className ? `${classes['rb-box-root']} ${className}` : `${classes['rb-box-root']}`}
      style={{
        paddingTop: pt ? theme.spacing(pt) : null,
        paddingBottom: pb ? theme.spacing(pb) : null,
        paddingLeft: pl ? theme.spacing(pl) : null,
        paddingRight: pr ? theme.spacing(pr) : null,
        marginTop: mt ? theme.spacing(mt) : null,
        marginBottom: mb ? theme.spacing(mb) : null,
        marginLeft: ml ? theme.spacing(ml) : null,
        marginRight: mr ? theme.spacing(mr) : null,
        columnGap: columnGap,
        rowGap: rowGap,
        display: display,
        justifyContent: justifyContent,
        alignItems: alignItems,
        alignSelf: alignSelf,
        flexGrow: flexGrow,
        flexDirection: flexDirection,
        flexWrap: flexWrap,
        gap: gap,
        ...style
      }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Box;
import React from 'react';
import classes from './Spacing.module.scss'

enum SpacingVariant {
    containerHorizontalPadding = 'rb-spacing-container-horizontal-padding',
    containerHorizontal = 'rb-spacing-container-horizontal',
    containerVerticalPadding = 'rb-spacing-container-vertical-padding',
    containerVerticalPaddingLg = 'rb-spacing-container-vertical-padding-lg',
    containerVerticalPaddingSmall = 'rb-spacing-container-vertical-padding-small',
    containerButtonOnBottom = 'rb-spacing-container-button-bottom',
    containerButtonOnBottomSmall = 'rb-spacing-container-button-bottomSmall',
    containerButtonOnBottomL = 'rb-spacing-container-button-bottomL',
    containerButtonOnBottomSmall2 = 'rb-spacing-container-button-bottomSmall2',
    containerButtonOnBottomWeb = 'rb-spacing-container-button-bottom-web',
    smallContainer = 'rb-spacing-small-container',
    fromHeaderToContentInSection = 'rb-spacing-from-header-to-content-in-section',
    betweenDashboardSections = 'rb-spacing-between-dashboard-sections',
    betweenPerksSections = 'rb-spacing-between-perks-sections',
    headerToContent = 'rb-spacing-header-to-content',
    titleToDivider = 'rb-spacing-title-to-divider',
    betweenCards = 'rb-spacing-between-cards',
    contentToCta = 'rb-spacing-content-to-cta',
    contentToCtaSmall = 'rb-spacing-content-to-cta-small',
    titleToCard = 'rb-spacing-title-to-card',
    betweenSummaryCards = 'rb-spacing-betwen-summarycards',
    betweenHeaderToSubheader = 'rb-spacing-between-header-to-subheader',
    betweenSections = 'rb-spacing-between-sections',
    betweenSectionsSmall = 'rb-spacing-between-sections-small',
    betweenSectionToInputFields = 'rb-spacing-between-section-to-input-fields',
    between2InlineInputFields = 'rb-spacing-between-2-inline-input-fields',
    betweenButtons = 'rb-spacing-between-buttons',
    betweenHeaderToTab = 'rb-spacing-between-header-to-tab',
    contentToButton = 'rb-spacing-content-to-button',
    tabToBody = 'rb-spacing-tab-to-body',
    between2Card='rb-spacing-between-2-card',
    onlyMobileContainer="rb-spacing-only-mobile-container",
    cotainerPadding='rb-spacing-cotainer-padding',
    betweenSectionsVerySmall="rb-spacing-between-sections-very-small",
    containerButtonOnBottomExtraSmall = 'rb-spacing-container-button-bottomExtraSmall',
    blog= 'rb-spacing-blog',
    containerButtonCustomPadding="rb-spacing-container-button-customPadding",
    subscriptions="rb-spacing-subscriptions",
    banner='rb-banner',
    rateTableSection='rb-rate-table-section',
    twoSideSpacing='rb-two-side-spacing',
    twoSideListSection="rb-two-side-list-section"
}

type SpacingProps = {
    variant: SpacingVariant | string;
    children?: string | React.ReactNode;
    /**
     * Pass an object for custom css
     */
}

const Spacing: React.FC<SpacingProps> = ({ variant, children }) => {
    return (
        <div className={classes[SpacingVariant[variant]]}>
            {children}
        </div>
    );
}

export default Spacing;